import { Box, Button, CardContent, Divider, type SxProps } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { WizardStepper } from './wizardStepper';

type StepContainerProps =
  | PropsWithChildren<{
      onBackClick: () => void;
      onNextClick: () => void;
      canGoNext: () => boolean;
      nextButtonText?: string;
      hideNavigation?: false;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex?: number;
      sx?: SxProps;
      navigationComponent?: React.ReactNode;
    }>
  | PropsWithChildren<{
      hideNavigation: true;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex: number;
      sx?: SxProps;
    }>;

export const StepContainer = (props: StepContainerProps) => {
  return (
    <Box className='h-full flex flex-col'>
      <Box className='flex-grow overflow-y-auto'>
        {!props.hideStepper && (
          <>
            <WizardStepper
              activeStep={props.stepperIndex ?? 0}
              stepsLabels={props.stepperLabels ?? []}
            />
            <Divider />
          </>
        )}
        <CardContent
          className='flex-grow'
          sx={{
            padding: '40px',
            ...props.sx,
          }}
        >
          {props.children}
        </CardContent>
      </Box>
      {!props.hideNavigation && (
        <Box
          className='flex justify-between items-center px-[40px] py-[16px]'
          sx={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)' }}
        >
          <div>{props.navigationComponent}</div>
          <div>
            <Button
              size='small'
              variant='outlined'
              onClick={props.onBackClick}
              className='mr-[12px]'
              data-testid='wizard-step-back-btn'
            >
              Back
            </Button>
            <Button
              size='small'
              variant='contained'
              disabled={!props.canGoNext()}
              onClick={props.onNextClick}
              data-testid='wizard-step-next-btn'
            >
              {props.nextButtonText || 'Next'}
            </Button>
          </div>
        </Box>
      )}
    </Box>
  );
};
