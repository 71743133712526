import { styled, Tooltip } from '@mui/material';
import type { Condition } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';

import { ApplicationsLogos, Environments } from '@/data/inventory/data';
import { CombineOperator, type FilterProperty } from '@/types/advanceFilter';

const ROW_GAP = 12;

export const ConditionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideBorder',
})(({ hideBorder }: { hideBorder?: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: hideBorder ? undefined : '1px solid var(--mui-palette-divider)',
  borderRadius: '4px',
  padding: '12px',
  fontWeight: 600,
  position: 'relative',
  [`&.${CombineOperator.And},&.${CombineOperator.Or}`]: {
    alignItems: 'flex-start',
    gap: ROW_GAP,
  },
  '&.hovered': {
    borderColor: 'var(--mui-palette-primary-main)',
  },
  '&.root': {
    border: 'none',
    padding: 0,
    flexDirection: 'column',
  },
  '&.highlighted-above::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    top: -7,
    borderTop: '1px solid var(--mui-palette-primary-main)',
    height: 1,
  },
  '&.highlighted-below::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -7,
    borderTop: '1px solid var(--mui-palette-primary-main)',
    height: 1,
  },
  '&.compact': {
    padding: 0,
    border: 0,
    '> img': { height: '100%' },
  },
}));

export const ConditionGroup = styled(ConditionContainer)({
  paddingLeft: '50px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: ROW_GAP,
  '&.compact': {
    gap: 6,
  },
});

const ConditionOperator = styled('div')({
  position: 'absolute',
  left: '-40px',
  top: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '4px',
  transform: `translateY(calc(-50% + ${ROW_GAP / 2}px))`,
  fontWeight: 400,
  fontSize: 12,
  '&::before': {
    content: '""',
    display: 'block',
    width: '12px',
    height: '12px',
    borderRadius: '4px 0 0 0',
    borderColor: 'currentColor',
    borderWidth: '1px 0 0 1px',
  },
  '&::after': {
    content: '""',
    display: 'block',
    width: '12px',
    height: '12px',
    borderRadius: '0 0 0 4px',
    borderColor: 'currentColor',
    borderWidth: '0 0 1px 1px',
  },
});

const ConditionValue = ({
  value,
  property,
}: {
  value: string[] | undefined;
  property?: string;
}) => {
  if (property === 'apps') {
    return value?.map((v, i) => (
      <Tooltip title={v} key={i}>
        <Image alt={v} src={ApplicationsLogos[v]} height={30} />
      </Tooltip>
    ));
  }

  if (property === 'environment') {
    return <>{value?.map((x) => Environments[x]?.title).join(', ')} </>;
  }

  return <>{value?.join(', ')} </>;
};

export default function ConditionViewer({
  condition,
  compact,
  supportProperties,
  parent,
  children,
}: {
  condition: Condition;
  compact?: boolean;
  supportProperties: FilterProperty[];
  parent?: Condition;
  children?: React.ReactNode;
}) {
  const cls = classNames(condition.operator, { compact: compact });
  switch (condition.operator) {
    case CombineOperator.And as string:
    case CombineOperator.Or as string:
      return (
        <ConditionGroup hideBorder={!parent} className={cls}>
          {condition.conditions?.map((c, i, arr) => (
            <ConditionViewer
              supportProperties={supportProperties}
              compact={compact}
              parent={condition}
              condition={c}
              key={i}
            >
              {i < arr.length - 1 && !compact && (
                <ConditionOperator>
                  {condition.operator.toUpperCase()}
                </ConditionOperator>
              )}
            </ConditionViewer>
          ))}
          {children}
        </ConditionGroup>
      );
    default:
      return (
        <ConditionContainer hideBorder={!parent} className={cls}>
          {supportProperties.find((c) => c.name === condition.property)
            ?.displayName ??
            condition.property ??
            ''}
          <span className='font-normal'>&nbsp;{condition.operator}&nbsp;</span>
          <ConditionValue
            property={condition.property}
            value={condition.value}
          />
          {children}
        </ConditionContainer>
      );
  }
}
