import { Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';

import { ConditionEditor } from '@/components/conditions/ConditionEditor';
import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceName,
  resourceType,
  sourceRegion,
  subnets,
  tags,
  vpc,
  resourceId,
} from '@/components/queryBuilder/properties';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { type FilterProperty } from '@/types/advanceFilter';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';
import BackupPolicyManualRule, {
  BackupPolicyManualRules,
} from './BackupPolicyManualRule';
import BackupPolicyManualRuleEditor, {
  ResourceOperation,
} from './BackupPolicyManualRuleEditor';

export const policySupportedProperties: FilterProperty[][] = [
  [resourceType, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets],
  [resourceName, resourceId, tags],
];

export const BackupPolicyConditionEditor = (
  props: StepProps<BackupPolicyEditState>
) => {
  const [addIncExc, setAddIncExc] = useState(false);
  return (
    <>
      <StepContainer
        stepperLabels={props.stepperLabels}
        stepperIndex={0}
        onBackClick={props.back}
        canGoNext={() => true}
        onNextClick={() => {
          props.setNextStep(
            props.currentStep.next?.find((s) => s.name === 'Resource list')
          );
        }}
      >
        <div>
          <Typography variant='body1' sx={{ mb: '24px' }}>
            Back up resources based on these conditions:
          </Typography>
          <ConditionEditor
            options={{
              supportGrouping: true,
              supportedProperties: policySupportedProperties,
            }}
            condition={props.state.condition}
            onChange={(condition) =>
              props.setState({ ...props.state, condition })
            }
          />
          <Divider />
          <Typography variant='body1' sx={{ mt: '24px', mb: '24px' }}>
            Include or exclude these resources:
          </Typography>
          {props.state.include?.length && props.state.include.length > 0 ? (
            <>
              <Typography variant='body2' sx={{ mb: '12px' }}>
                Include:
              </Typography>
              <BackupPolicyManualRules>
                {props.state.include?.map((resource) => {
                  return (
                    <BackupPolicyManualRule
                      resourceId={resource}
                      onDelete={(resourceId) => {
                        props.setState({
                          ...props.state,
                          include: props.state.include?.filter(
                            (r) => r !== resourceId
                          ),
                        });
                      }}
                      key={resource}
                    />
                  );
                })}
              </BackupPolicyManualRules>
            </>
          ) : (
            <></>
          )}
          {props.state.exclude?.length && props.state.exclude.length > 0 ? (
            <>
              <Typography variant='body2' sx={{ mt: '24px', mb: '12px' }}>
                Exclude:
              </Typography>
              <BackupPolicyManualRules>
                {props.state.exclude?.map((resource) => {
                  return (
                    <BackupPolicyManualRule
                      resourceId={resource}
                      key={resource}
                      onDelete={(resourceId) => {
                        props.setState({
                          ...props.state,
                          exclude: props.state.exclude?.filter(
                            (r) => r !== resourceId
                          ),
                        });
                      }}
                    />
                  );
                })}
              </BackupPolicyManualRules>
            </>
          ) : (
            <></>
          )}

          <div style={{ marginTop: '24px' }}>
            {addIncExc && (
              <BackupPolicyManualRuleEditor
                onFinish={(operation, resource) => {
                  if (resource && resource.length > 8) {
                    if (operation === ResourceOperation.Include) {
                      props.setState({
                        ...props.state,
                        include: [...(props.state.include || []), resource],
                      });
                    } else {
                      props.setState({
                        ...props.state,
                        exclude: [...(props.state.exclude || []), resource],
                      });
                    }
                  }

                  setAddIncExc(false);
                }}
                onAbort={() => {
                  setAddIncExc(false);
                }}
              />
            )}
            {!addIncExc && (
              <Button
                onClick={() => {
                  setAddIncExc(true);
                }}
                variant='outlined'
                sx={{
                  borderColor: 'transparent',
                  width: 32,
                  height: 32,
                  padding: 0,
                  minWidth: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i className='material-symbols-add-rounded' />
              </Button>
            )}
          </div>
        </div>
      </StepContainer>
    </>
  );
};
